import { useRouter } from 'next/compat/router';
import { Route, route } from 'nextjs-routes';
import { useEffect } from 'react';
import equalityFn from 'zustand/shallow';
import { LoadingSpinner } from '~/components/LoadingSpinner';
import { Routes, routeToUrl } from '~/modules/routes';
import { userSessionStore, useUserSessionStore } from '~/stores/userSessionStore';
import { EntityStoreStatuses } from '~/util/createFetchStore';
import { usePushRoute } from '~/util/routes';

export function RequireAuth({ children, showLoading = true, redirectIfNotLoggedIn = false, redirectTo = undefined }) {
  const pushRoute = usePushRoute();
  const { push, pathname, query } = useRouter();
  const [user, status] = useUserSessionStore((state) => [state.data, state.status], equalityFn);

  const checkIsLoggedInDependencies = [
    status,
    redirectIfNotLoggedIn,
    pathname,
    JSON.stringify(
      Object.keys(query)
        .sort()
        .map((key) => `${key}:${query[key]}`),
    ),
  ];

  useEffect(function checkIsLoggedIn() {
    // Always get the latest state from the store within effects
    const { status } = userSessionStore.getState();
    switch (status) {
      case EntityStoreStatuses.UNINITIALIZED: {
        userSessionStore.fetch();
        break;
      }
      case EntityStoreStatuses.ERROR: {
        if (redirectIfNotLoggedIn) {
          // Login failed, redirect to signin
          const pushQuery: Record<string, string> = { auth_type: 'signin' };
          try {
            pushQuery.next = route({ pathname, query } as Route);
          } catch {
            /* noop */
          }
          pushRoute('/auth/[auth_type]', pushQuery);
        }
        break;
      }
      case EntityStoreStatuses.LOADED: {
        if (pathname === '/auth/[auth_type]' && query.auth_type === 'signin') {
          // Login success in signin page (redirect away from signin page)
          push((query.next as string) || routeToUrl(Routes.AFTER_LOGIN));
        }
        break;
      }
      default: {
        /* no op */
      }
    }
  }, checkIsLoggedInDependencies);

  if (status === EntityStoreStatuses.LOADING) {
    return showLoading ? (
      <div className="flex flex-col container py-96 items-center">
        <div className="flex">
          <LoadingSpinner />
          <span className="pb-2 pl-2">Authenticating, please wait...</span>
        </div>
      </div>
    ) : null;
  }

  if (!user) {
    return null;
  }

  return children;
}
